import customParseFormat from "dayjs/plugin/customParseFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { registerPlugins } from "./plugins";
import App from "./App.vue";
import "./styles/main.css";
import dayjs from "dayjs";
import "dayjs/locale/fr";

dayjs.locale("fr");
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);

const app = createApp(App);

registerPlugins(app).then(() => app.mount("#app"));

/**
 * plugins/index.js
 *
 * Included in `./src/main.js`
 */

// Plugins
import { createRouter } from "../router";
import { createI18n } from "./i18n";
import { createStores } from "./pinia";
import { createOFetch } from "./ofetch";
import { createVuetify } from "./vuetify";
import { createValidator } from "./validator";
import { createPhoneInput } from "./phone-input";

/**
 *
 * @param {import("vue").App<Element>} app
 */
export async function registerPlugins(app) {
  const stores = createStores();
  app.use(stores);

  await useSettingsStore().loadSettings();

  const i18n = await createI18n();
  app.use(i18n);

  const validator = createValidator(i18n);
  app.use(validator);

  const ofetch = createOFetch();
  app.use(ofetch);

  const router = createRouter();
  app.use(router);

  const vuetify = createVuetify(i18n);
  app.use(vuetify);

  const phoneInput = createPhoneInput(i18n);
  app.use(phoneInput);

  return app;
}

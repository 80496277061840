import { ofetch } from "ofetch";
import { nanoid } from "nanoid";
import { defineStore } from "pinia";

export const useSettingsStore = defineStore("settings", () => {
  /** @type {Ref<Awaited<ReturnType<typeof import("./utils/generated/api-public").getSettings>>>} */
  const settings = ref({
    stripePublicKey: "",
  });
  /** @type {Ref<string>} */
  const sid = ref(nanoid(5));

  async function loadSettings() {
    settings.value = await ofetch("/api/public/settings", { headers: { Sid: sid.value } });
    return settings.value;
  }

  return {
    settings,
    sid,
    loadSettings,
  };
});
